import { Button, Card, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { forwardRef } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function DocModal({ fullWidth, fullScreen, maxWidth, open, handleClose, handleDownload, content, fileName }) {
    return (
        <Dialog
            fullWidth={fullWidth}
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll="paper"
        >
            <DialogTitle>
                <Typography variant="h4" color="primary">
                    Document
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                {fileName !== '' && ['jpeg', 'jpg', 'png'].includes(fileName.split('.').pop().toLowerCase()) ? (
                    <Card>
                        <CardMedia component="img" height="100%" image={fileName} alt={`Image `} />
                    </Card>
                ) : (
                    content
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange">
                    Cancel
                </Button>
                <Button onClick={handleDownload} variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange">
                    Download
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DocModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'md'
};

export default DocModal;
