import { useState, useEffect } from 'react';

import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import { Box, Button, Grid, IconButton, InputAdornment, MenuItem, Skeleton, Stack, TextField } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import NewFileTypeModal from 'ui-component/modals/NewFileTypeModal';
import PropTypes from 'prop-types';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const MAddCompSafetyComplianceForm = ({ id, docId, typeId, tId, reloadData, handleClose }) => {
    const dispatch = useDispatch();

    const [docList, setDocList] = useState();

    const [loading, setLoading] = useState();

    const [addError, setAddError] = useState();
    const [open, setOpen] = useState(false);

    // const scriptedRef = useScriptRef();
    // const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [docData, setDocData] = useState();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const driverConfig = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleClickOpenModal = (e) => {
        e === 'add' ? setOpen(true) : '';
    };

    const safteyDocList = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/fleet/safety/doc-type-dq/list`, config);

            setDocList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getDocData = async () => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/company/cmp-docs/view/${docId}`, config);
            setDocData(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (!docList) {
            safteyDocList();
        }
        if (docId && !docData) {
            getDocData();
        }
    }, [safteyDocList]);

    const MemberSchema = Yup.object().shape({
        fileType: Yup.string().required('File Type is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            startDate: docId && docData ? docData.issue_date : '',
            endDate: docId && docData ? docData.expiry_date : '',
            fileType: typeId,
            dqStatus: docId && docData ? docData.doc_status : '',
            file: docId && docData ? docData.sft_doc : '',
            fileName: docId && docData ? docData.sft_doc_name : '',
            note: docId && docData ? docData.description : ''
        },
        validationSchema: MemberSchema,
        onSubmit: async () => {
            try {
                if (docId) {
                    const { data } = await axios.put(
                        `/v1/api/users/company/cmp-docs/view/${docId}`,
                        {
                            company_id: loginInfoFromStorage.company_id,
                            sft_doc_name: values.fileName,
                            sft_doc: values.file,
                            sft_doc_user_type: 'truck',
                            description: values.note,
                            truck_id: tId,
                            driver_id: id,
                            doc_type_id: values.fileType,
                            doc_status: values.dqStatus,
                            issue_date: values.startDate ? format(new Date(values.startDate), 'yyyy-MM-dd hh:mm') : '',
                            expiry_date: values.endDate ? format(new Date(values.endDate), 'yyyy-MM-dd hh:mm') : '',
                            created_by: 0
                        },
                        driverConfig
                    );
                    dispatch(openSuccessSnackbar('Driver Qualification file added successfully'));
                    reloadData();
                } else {
                    const { data } = await axios.post(
                        `/v1/api/users/company/cmp-docs/add/`,
                        {
                            company_id: loginInfoFromStorage.company_id,
                            sft_doc_name: values.fileName,
                            sft_doc: values.file,
                            sft_doc_user_type: 'truck',
                            description: values.note,
                            truck_id: tId,
                            driver_id: id,
                            doc_type_id: values.fileType,
                            doc_status: values.dqStatus,
                            issue_date: values.startDate ? format(new Date(values.startDate), 'yyyy-MM-dd') : '',
                            expiry_date: values.endDate ? format(new Date(values.endDate), 'yyyy-MM-dd') : '',
                            created_by: 0
                        },
                        driverConfig
                    );
                    dispatch(openSuccessSnackbar('DQ file added successfully'));
                    reloadData();
                }
            } catch (error) {
                setAddError(error.response.data);
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        {/* DQ type */}
                        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    select
                                    type="text"
                                    label="DQ File Type"
                                    value={values.fileType}
                                    onChange={(e) => {
                                        setFieldValue('fileType', e.target.value);
                                        handleClickOpenModal(e.target.value);
                                    }}
                                    error={Boolean((touched.fileType && errors.fileType) || (addError && addError.doc_type_id))}
                                    helperText={(touched.fileType && errors.fileType) || (addError && addError.doc_type_id)}
                                >
                                    <MenuItem disabled selected>
                                        DQ File Type
                                    </MenuItem>
                                    <MenuItem value="add" sx={{ color: '#D3505F' }}>
                                        Add New File Type
                                    </MenuItem>
                                    {docList &&
                                        docList.map((i) => (
                                            <MenuItem key={i.sft_doc_type_id} value={i.sft_doc_type_id}>
                                                {i.stf_doc_type_name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            )}
                        </Grid> */}

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            {loading ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Date"
                                        openTo="day"
                                        views={['day']}
                                        value={values.startDate}
                                        onChange={(newValue) => {
                                            setFieldValue(`startDate`, newValue);
                                        }}
                                        renderInput={(params) => <TextField color="orange" fullWidth {...params} error={null} />}
                                    />
                                </LocalizationProvider>
                            )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            {loading ? (
                                <Skeleton variant="rectangular" width="100%" height="50px" />
                            ) : (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        className="text-input"
                                        label="Expire Date"
                                        openTo="day"
                                        views={['day']}
                                        value={values.endDate}
                                        onChange={(newValue) => {
                                            setFieldValue(`endDate`, newValue);
                                        }}
                                        renderInput={(params) => <TextField color="orange" fullWidth {...params} error={null} />}
                                    />
                                </LocalizationProvider>
                            )}
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    className="text-input"
                                    color="orange"
                                    fullWidth
                                    value={values.fileName}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end">
                                                    <Button component="label" color="orange" startIcon={<CloudUploadIcon />}>
                                                        Upload
                                                        <input
                                                            hidden
                                                            type="file"
                                                            onInputCapture={(event) => {
                                                                setFieldValue('file', event.target.files[0]);
                                                                setFieldValue('fileName', event.target.files[0].name);
                                                            }}
                                                        />
                                                    </Button>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    label="File"
                                />
                            )}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {loading ? (
                                <Skeleton height="50px" />
                            ) : (
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    multiline
                                    minRows={5}
                                    maxRows={5}
                                    type="textarea"
                                    label="Note"
                                    {...getFieldProps('note')}
                                    // error={Boolean(
                                    //     (touched.companyName && errors.companyName) ||
                                    //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // )}
                                    // helperText={
                                    //     (touched.companyName && errors.companyName) ||
                                    //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                    //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                    // }
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Stack direction="row" sx={{ mt: 2 }} alignItems="center" justifyContent="center" gap={2}>
                        <AnimateButton>
                            <LoadingFormButton type="button" btnText="Cancel" load={false} onClickHandler={handleClose} />
                        </AnimateButton>
                        <AnimateButton>
                            <LoadingFormButton type="submit" btnText="Save" load={isSubmitting} />
                        </AnimateButton>
                    </Stack>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
            <NewFileTypeModal open={open} handleClose={handleCloseModal} type="dq" safteyDocList={safteyDocList} />
        </>
    );
};

MAddCompSafetyComplianceForm.propTypes = {
    reloadData: PropTypes.any,
    id: PropTypes.any,
    docId: PropTypes.any
};

export default MAddCompSafetyComplianceForm;
