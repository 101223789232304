import { Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
function ViewFileFields({ sx, viewText, fileName, file, icon, handleClickOpen, docId, handleEdit, handleDownloadClick, handleDelete }) {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ mt: 2, height: '30px', ...sx }}>
            <Grid item lg={3} md={3} sm={12} xs={12}>
                <span style={{ fontFamily: 'Lexend', fontWeight: '500' }}>{viewText}</span>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12}>
                <div style={{ flexDirection: 'row' }}>
                    {/* <span style={{ width: '228px', height: '30px', color: '#5C5C5C', fontFamily: 'Lexend', py: '8px' }}>{fileName}</span> */}

                    <IconButton onClick={() => handleClickOpen(file)}>
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEdit(docId)}>
                        <EditIcon fontSize="small" />
                    </IconButton>

                    <IconButton onClick={() => handleDelete(docId)} color="error">
                        <DeleteIcon fontSize="small" />
                    </IconButton>

                    {/* <IconButton disabled={!file} onClick={() => handleDownloadClick(file)}>
                            <DownloadIcon />
                        </IconButton> */}
                </div>
            </Grid>
        </Stack>
    );
}

export default ViewFileFields;
