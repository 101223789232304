import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { Button, FormControl, Grid, Link, Skeleton, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import SubCard from 'ui-component/cards/SubCard';
import axios from 'axios';

import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';

import ViewFields from 'ui-component/ViewFields';
import DocModal from 'ui-component/modals/DocModal';

import { Worker, Viewer } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import ViewTextFields from 'ui-component/ViewTextFields';
import { Box, Stack } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PatternFormat } from 'react-number-format';
import CQListModal from './CQListModal';
import ViewFileFields from 'ui-component/ViewFileFields';
import NewFileTypeModal from './NewFileTypeModal';
import DeleteConfirmModal from 'ui-component/modals/DeleteConfirmModal';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />
        }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 60,
        width: '100%'
        // backgroundColor: '#0A95FF'
    },
    '& .Mui-selected': {
        color: '#0A95FF',
        background: '#98c1e094',
        borderRadius: '2rem'
    },
    '& .MuiButtonBase-root': {
        minHeight: '50px'
    }
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    marginRight: theme.spacing(1),
    color: '#697586',
    '&.Mui-selected': {
        color: '#0A95FF'
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
}));
const ViewOrganizationForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [stateList, setStateList] = useState();
    const [cityList, setCityList] = useState();
    const [cityName, setCityName] = useState('');

    const [open2, setOpen2] = useState(false);
    const [docName, setDocName] = useState('');
    const [docId, setDocId] = useState(null);

    const [fileName, setFileName] = useState('');
    const [stateName, setStateName] = useState('');
    const [companySetup, setCompanySetup] = useState();

    const [openDelete, setOpenDelete] = useState(false);
    const [openFile, setOpenFile] = useState(false);

    const handleClickOpen = (file) => {
        setFileName(file);
        setModalOpen(true);
    };

    const handleClose = () => {
        // setFileName();
        setModalOpen(false);
    };

    function handleDownloadModal() {
        window.open(fileName, '_blank');
        setFileName();
        setModalOpen(false);
    }

    function handleDownloadClick(file) {
        if (file) {
            window.open(file, '_blank');
        }
    }

    const handleListCloseModal = () => {
        setOpen2(false);
    };

    const handleListOpenModal = (docName, docId) => {
        setOpen2(true);
        setDocName(docName);
        setDocId(docId);
    };

    const handleFileModalClose = () => {
        setDocId(null);

        setOpenFile(false);
        fetchCompany();
    };

    const handleEditOpenModal = (id) => {
        if (id !== 0) {
            setDocId(id);
        }
        // setDocName(doc_name);
        setOpenFile(true);
    };

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage && loginInfoFromStorage.company_id ? loginInfoFromStorage.company_id : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    const navigate = useNavigate();
    // const { companySetup } = props;

    const editAccounting = () => {
        navigate(`/company/accounting/edit`);
    };

    const editOrganization = () => {
        navigate(`/company/profile/edit`);
    };

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }

        if (companySetup && !stateList) {
            fetchState();
        }

        if (companySetup && companySetup.state_id && stateList && !cityList) {
            const stateData = stateList.filter((i) => i.id === companySetup.state_id);

            setStateName(stateData[0].name);
            getCityList(companySetup.state_id);
        }

        if (cityList) {
            const cityData = cityList.filter((i) => i.id === companySetup.city_id);

            setCityName(cityData[0].name);
        }
    }, [stateList, cityList]);

    const fetchState = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/state/list/233/`, config);

            setStateList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchCompany = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);

            setCompanySetup(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const getCityList = async (id) => {
        setLoading(true);

        try {
            const { data } = await axios.get(`/v1/api/users/city/list/${id}/`, config);

            setCityList(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    // ===================== Delete Handlers =====================
    const deleteFileType = async () => {
        try {
            const { data } = await axios.delete(`/v1/api/users/company/cmp-doc-type/view/${docId}`, config);
            setOpenDelete(false);
            setDocId(null);
            fetchCompany();
        } catch (error) {
            console.log(error);
        }
    };
    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setDocId(id);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setDocId(null);
    };
    // ===================== Delete Handlers =====================

    return (
        <>
            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                <Grid container spacing={1}>
                    {/* Customer */}
                    {/* General Info */}

                    <Box
                        sx={{
                            width: '100%',
                            typography: 'body1',
                            border: 'none',
                            backgroundColor: 'transparent'
                        }}
                    >
                        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                            {/* <LoadingFormButton type="button" btnText="Edit" onClick={() => editOrganization} /> */}

                            <Button
                                sx={{ mt: '27px', color: 'white', backgroundColor: '#EB812E' }}
                                color="orange"
                                variant="contained"
                                onClick={editOrganization}
                            >
                                Edit Profile
                            </Button>
                            <Button sx={{ mt: '27px', color: 'white', backgroundColor: '#EB812E' }} color="orange" variant="contained" onClick={editAccounting}>
                                Edit Invoice Details
                            </Button>
                        </Stack>
                        <div style={{ padding: 15 }}>
                            <div>
                                <img src={companySetup && companySetup.logo} alt="logo" width="200px" />
                            </div>
                            <Typography variant="h2" sx={{ mt: 3, fontWeight: 600 }} color="#0A95FF">
                                {companySetup ? companySetup.dsp_company_name : ''}
                            </Typography>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                    MC #.
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 3,
                                        fontWeight: 500,
                                        marginLeft: 4
                                    }}
                                >
                                    {companySetup ? companySetup.mc_no : ''}
                                </Typography>
                            </Stack>

                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                    EINO #
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 3,
                                        fontWeight: 500,
                                        marginLeft: 4
                                    }}
                                >
                                    {companySetup ? companySetup.ein_no : ''}
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography variant="h4" sx={{ mt: 3, fontWeight: 500 }}>
                                    DOT #
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 3,
                                        fontWeight: 500,
                                        marginLeft: 4
                                    }}
                                >
                                    {companySetup ? companySetup.dot_no : ''}
                                </Typography>
                            </Stack>
                        </div>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            aria-label="styled tabs example"
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    flexWrap: 'wrap'
                                }
                            }}
                        >
                            <StyledTab label="Contacts" icon={<PermContactCalendarIcon />} iconPosition="start" value="1" />
                            <StyledTab label="Address" icon={<HomeIcon />} iconPosition="start" value="2" />
                            <StyledTab label="Bank" icon={<AccountBalanceIcon />} iconPosition="start" value="3" />
                            <StyledTab label="Invoice Company Details" icon={<AssessmentIcon />} iconPosition="start" value="4" />
                            <StyledTab label="Factoring Details" icon={<AssessmentIcon />} iconPosition="start" value="5" />
                            <StyledTab label="Qualification Files" icon={<AssessmentIcon />} iconPosition="start" value="6" />
                        </StyledTabs>
                        <Box sx={{ px: '20px' }}>
                            {value == 1 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Email Id
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `mailto:${companySetup ? companySetup.email : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.email : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Phone Number
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `tel:${companySetup ? companySetup.phone : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup && companySetup.phone !== '' ? (
                                                        <PatternFormat value={companySetup.phone} displayType={'text'} format="###-###-####" />
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                EXT.
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <Link
                                                    underline="none"
                                                    component="button"
                                                    onClick={(e) => {
                                                        window.location.href = `tel:${companySetup ? companySetup.phone_ext : ''}`;
                                                        e.preventDefault();
                                                    }}
                                                    sx={{
                                                        color: '#0A95FF',
                                                        height: '20px',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.phone_ext : 'N/A'}
                                                </Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 2 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Building Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.address : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                State
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.state_name : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                City
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.city_name : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Zip
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.zip_code : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 3 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Bank Name
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.bank_name : ''}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Bank Account Number
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.acc_no : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 4 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Billing Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.billing_address : ''}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Billing Phone No
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup && companySetup.billing_phone !== '' ? (
                                                        <PatternFormat value={companySetup.billing_phone} displayType={'text'} format="###-###-####" />
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Fax No
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup && companySetup.billing_fax !== '' ? (
                                                        <PatternFormat value={companySetup.billing_fax} displayType={'text'} format="###-###-####" />
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 5 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Factoring Company Name
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.fact_info.fact_comp_name : ''}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Factoring Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.fact_info.fact_address : ''}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Factoring Phone No
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup && companySetup.fact_info.fact_phone !== '' ? (
                                                        <PatternFormat value={companySetup.fact_info.fact_phone} displayType={'text'} format="###-###-####" />
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Fax No
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup && companySetup.fact_info.fact_fax !== '' ? (
                                                        <PatternFormat value={companySetup.fact_info.fact_fax} displayType={'text'} format="###-###-####" />
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Factoring Client Number
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.fact_info.fact_client_no : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={5} xs={5}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography
                                                sx={{
                                                    mt: 3,
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Factoring Email Address
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={9} md={9} sm={6} xs={6}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <Typography sx={{ mt: 3 }}>
                                                <span
                                                    style={{
                                                        width: '228px',
                                                        height: '20px',
                                                        color: '#5C5C5C',
                                                        fontFamily: 'Lexend'
                                                    }}
                                                >
                                                    {companySetup ? companySetup.fact_info.fact_email_address : 'N/A'}
                                                </span>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}

                            {value == 6 && (
                                <Grid container spacing={1}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Button
                                            variant="contained"
                                            sx={{ mt: 2, color: 'white', backgroundColor: '#EB812E' }}
                                            color="orange"
                                            onClick={() => handleEditOpenModal(null)}
                                        >
                                            Add New File Type
                                        </Button>
                                    </Grid>
                                    {companySetup && companySetup.doc_types ? (
                                        companySetup.doc_types.map((data, index) => (
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <ViewFileFields
                                                    // file={data.sft_doc !== 'null' ? data.sft_doc : ''}
                                                    // fileName={data.sft_doc_name !== 'null' ? data.sft_doc_name : ''}
                                                    viewText={data.stf_doc_type_name}
                                                    handleClickOpen={() => handleListOpenModal(data.stf_doc_type_name, data.comp_doc_type_id)}
                                                    handleEdit={() => handleEditOpenModal(data.comp_doc_type_id)}
                                                    handleDelete={() => handleOpenDelete(data.comp_doc_type_id)}
                                                    handleDownloadClick={handleDownloadClick}
                                                />
                                            </Grid>
                                        ))
                                    ) : (
                                        <Skeleton height="50px" />
                                    )}{' '}
                                </Grid>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </SubCard>

            <DocModal
                open={modalOpen}
                fullWidth
                fullScreen
                maxWidth="md"
                handleClose={() => handleClose()}
                handleDownload={() => handleDownloadModal()}
                fileName={fileName}
                content={
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileName} />
                    </Worker>
                }
            />
            <CQListModal open={open2} handleClose={handleListCloseModal} docName={docName} id={cid} docId={docId} cid={cid} />
            <NewFileTypeModal open={openFile} handleClose={handleFileModalClose} id={docId} />
            <DeleteConfirmModal open={openDelete} handleClose={handleCloseDelete} deleteHandler={deleteFileType} />
        </>
    );
};

export default ViewOrganizationForm;
