import { useState } from 'react';

import { Box, Tab } from '@mui/material';

import SubCard from 'ui-component/cards/SubCard';

import axios from 'axios';

import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useDispatch } from 'react-redux';
import ImportPanel from '../includes/ImportPanel';

import drvFile from 'assets/docs/driver-list.xlsx';
import trkFile from 'assets/docs/truck-list.xlsx';
import trlFile from 'assets/docs/trailer-list.xlsx';
import ldFile from 'assets/docs/load-list.xlsx';
import azFile from 'assets/docs/az-load-list.csv';
import csFile from 'assets/docs/customer-list.xlsx';
import ImportPanelAz from '../includes/ImportPanelAz';
import { useEffect } from 'react';
import ImportDriverData from 'ui-component/modals/ImportDriverData';
import ImportTruckData from 'ui-component/modals/ImportTruckData';
import ImportAzLoadData from 'ui-component/modals/ImportAzLoadData';
import ImportTrailerData from 'ui-component/modals/ImportTrailerData';
import ImportCustomerData from 'ui-component/modals/ImportCustomerData';
import ImportLoadData from 'ui-component/modals/ImportLoadData';
import AzLoadData from 'ui-component/modals/AzLoadData';
import DrvLoadData from 'ui-component/modals/DrvLoadData';
import TrkLoadData from 'ui-component/modals/TrkLoadData';
import TrlLoadData from 'ui-component/modals/TrlLoadData';
import CsLoadData from 'ui-component/modals/CsLoadData';
import LdLoadData from 'ui-component/modals/LdLoadData';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const DataImportForm = () => {
    const [value, setValue] = useState('1');

    const [openDrvModal, setOpenDrvModal] = useState(false);
    const [openTrkModal, setOpenTrkModal] = useState(false);
    const [openTrlModal, setOpenTrlModal] = useState(false);
    const [openLdModal, setOpenLdModal] = useState(false);
    const [openAzLdModal, setOpenAzLdModal] = useState(false);
    const [openCsModal, setOpenCsModal] = useState(false);

    const [ldAzForm, setLdAzForm] = useState(false);

    const [drvData, setDrvData] = useState();
    const [trkData, setTrkData] = useState();
    const [trlData, setTrlData] = useState();
    const [ldData, setLdData] = useState();
    const [ldAzData, setLdAzData] = useState();
    const [csData, setCsData] = useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const dispatch = useDispatch();

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const [loadingFile, setLoadingFile] = useState(false);

    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
        // responseType: 'arraybuffer'
    };

    const jsonConfig = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
        // responseType: 'arraybuffer'
    };

    const [driver, setDriver] = useState('');

    const driverFileUploadHandler = async (e) => {
        e.preventDefault();
        setLoadingFile(true);
        setDrvData(null);

        try {
            const formData = new FormData();

            formData.append('file', e.target.files[0]);

            const { data } = await axios.post(`/v1/api/users/company/drivers-bulk/preview/`, formData, config);

            // const outputFilename = `driver-${Date.now()}.xlsx`;

            // download file automatically using link attribute.
            // const url = URL.createObjectURL(new Blob([data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', outputFilename);
            // document.body.appendChild(link);
            // link.click();

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Driver data uploaded successfully'));

            setDrvData(data.data);
            // setOpenDrvModal(true);
        } catch (error) {
            console.log(error.response.data.error);
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
            setLoadingFile(false);
        }
    };

    const onDrvSave = async (val) => {
        setLoadingFile(true);
        setDrvData(null);
        try {
            const { data } = await axios.post(`/v1/api/users/company/drivers-bulk/add/`, val, jsonConfig);

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Driver data proccessed successfully'));
            setDrvData(data.data);

            //  setLdAzData(data.data);
            // setOpenAzLdModal(true);
        } catch (error) {
            setLoadingFile(false);
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
        }
    };

    const truckFileUploadHandler = async (e) => {
        e.preventDefault();
        setLoadingFile(true);
        setTrkData(null);

        try {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);

            const { data } = await axios.post(`/v1/api/fleet/truck-bulk/preview`, formData, config);

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Truck data uploaded successfully'));
            setTrkData(data.data);
            // setOpenTrkModal(true);
        } catch (error) {
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
            setLoadingFile(false);
        }
    };

    const trailerFileUploadHandler = async (e) => {
        e.preventDefault();
        setLoadingFile(true);
        setTrkData(null);

        try {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);

            const { data } = await axios.post(`/v1/api/fleet/trailer-bulk/preview`, formData, config);

            // const outputFilename = `trailer-${Date.now()}.xlsx`;

            // // download file automatically using link attribute.
            // const url = URL.createObjectURL(new Blob([data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', outputFilename);
            // document.body.appendChild(link);
            // link.click();

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Trailer data uploaded successfully'));
            setTrlData(data.data);
            // setOpenTrkModal(true);
        } catch (error) {
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
            setLoadingFile(false);
        }
    };

    const loadFileUploadHandler = async (e) => {
        e.preventDefault();
        setLoadingFile(true);
        setLdData(null);

        try {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);

            const { data } = await axios.post(`/v1/api/dispatch/load-bulk/preview`, formData, config);

            // const outputFilename = `load-${Date.now()}.xlsx`;

            // // download file automatically using link attribute.
            // const url = URL.createObjectURL(new Blob([data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', outputFilename);
            // document.body.appendChild(link);
            // link.click();

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Load data uploaded successfully'));
            setLdData(data.data);
            // setOpenLdModal(true);
        } catch (error) {
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
            setLoadingFile(false);
        }
    };

    const onLdSave = async (val) => {
        setLoadingFile(true);
        setLdData(null);
        try {
            const { data } = await axios.post(`/v1/api/dispatch/load-bulk/add`, val, jsonConfig);

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Load data proccessed successfully'));
            setLdData(data.data);

            //  setLdAzData(data.data);
            // setOpenAzLdModal(true);
        } catch (error) {
            setLoadingFile(false);
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
        }
    };

    const azLoadFileUploadHandler = async (e) => {
        e.preventDefault();

        if (driver === '' || !driver.driver_id) {
            dispatch(openErrorSnackbar('Please Select A Driver'));
            return;
        }
        setLoadingFile(true);
        try {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            formData.append('driver_id', driver.driver_id);

            const { data } = await axios.post(`/v1/api/dispatch/load-bulk-amzn-img/preview`, formData, config);

            // const outputFilename = `load-${Date.now()}.xlsx`;

            // // download file automatically using link attribute.
            // const url = URL.createObjectURL(new Blob([data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', outputFilename);
            // document.body.appendChild(link);
            // link.click();

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Load data proccessed successfully'));

            setLdAzData(data.data);
            // setOpenAzLdModal(true);
        } catch (error) {
            setLoadingFile(false);
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
        }
    };

    const onAzSave = async (val) => {
        if (driver === '' || !driver.driver_id) {
            dispatch(openErrorSnackbar('Please Select A Driver'));
            return;
        }
        setLoadingFile(true);
        setLdAzData(null);
        try {
            const { data } = await axios.post(`/v1/api/dispatch/load-bulk-amzn/add`, { load_data: val, driver_id: driver.driver_id }, jsonConfig);

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Load data proccessed successfully'));
            console.log(data.data);

            setLdAzData(data.data);
            // setOpenAzLdModal(true);
        } catch (error) {
            setLoadingFile(false);
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
        }
    };

    const onTrkSave = async (val) => {
        setLoadingFile(true);
        setTrkData(null);
        try {
            const { data } = await axios.post(`/v1/api/fleet/truck-bulk/add`, val, jsonConfig);

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Truck data proccessed successfully'));
            console.log(data.data);
            setTrkData(data.data);

            //  setLdAzData(data.data);
            // setOpenAzLdModal(true);
        } catch (error) {
            setLoadingFile(false);
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
        }
    };

    const onTrlSave = async (val) => {
        setLoadingFile(true);
        setTrlData(null);
        try {
            const { data } = await axios.post(`/v1/api/fleet/trailer-bulk/add`, val, jsonConfig);

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Trailer data proccessed successfully'));
            setTrlData(data.data);

            //  setLdAzData(data.data);
            // setOpenAzLdModal(true);
        } catch (error) {
            setLoadingFile(false);
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
        }
    };

    const customerFileUploadHandler = async (e) => {
        e.preventDefault();
        setLoadingFile(true);
        setCsData(null);

        try {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);

            const { data } = await axios.post(`/v1/api/dispatch/customer-bulk/preview`, formData, config);

            // const outputFilename = `load-${Date.now()}.xlsx`;

            // // download file automatically using link attribute.
            // const url = URL.createObjectURL(new Blob([data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', outputFilename);
            // document.body.appendChild(link);
            // link.click();

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Load data proccessed successfully'));
            setCsData(data.data);
            // setOpenCsModal(true);
        } catch (error) {
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
            setLoadingFile(false);
        }
    };

    const onCsSave = async (val) => {
        setLoadingFile(true);
        setCsData(null);
        try {
            const { data } = await axios.post(`/v1/api/dispatch/customer-bulk/add`, val, jsonConfig);

            setLoadingFile(false);

            dispatch(openSuccessSnackbar('Customer data proccessed successfully'));
            setCsData(data.data);

            //  setLdAzData(data.data);
            // setOpenAzLdModal(true);
        } catch (error) {
            setLoadingFile(false);
            if (error.response.data.error) {
                dispatch(openErrorSnackbar(error.response.data.error));
            } else {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
        }
    };

    const closeDrvHandler = () => {
        setOpenDrvModal(false);
    };

    const closeTrkHandler = () => {
        setOpenTrkModal(false);
    };

    const closeTrlHandler = () => {
        setOpenTrlModal(false);
    };

    const closeLdHandler = () => {
        setOpenLdModal(false);
    };

    const closeLdAzHandler = () => {
        setOpenAzLdModal(false);
    };

    const closeCsHandler = () => {
        setOpenCsModal(false);
    };
    return (
        <>
            <SubCard sx={{ mt: 5, background: 'transparent', borderRadius: '8px', mb: 2, boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.06)' }}>
                <TabContext value={value}>
                    <Box sx={{ border: 'none', background: 'transparent' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            sx={{
                                '.MuiTabs-flexContainer': {
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                },
                                '.MuiTabs-indicator': {
                                    backgroundColor: 'transparent'
                                },
                                '.MuiTab-root.Mui-selected': {
                                    color: '#EB812E !important'
                                }
                            }}
                        >
                            <Tab sx={{ fontSize: '16px', fontWeight: 500 }} label="Driver" value="1" />
                            <Tab sx={{ fontSize: '16px', fontWeight: 500 }} label="Truck" value="2" />
                            <Tab sx={{ fontSize: '16px', fontWeight: 500 }} label="Trailer" value="3" />
                            <Tab sx={{ fontSize: '16px', fontWeight: 500 }} label="Customer" value="4" />
                            <Tab sx={{ fontSize: '16px', fontWeight: 500 }} label="Load" value="5" />
                            <Tab sx={{ fontSize: '16px', fontWeight: 500 }} label="Amazon Load" value="6" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <ImportPanel
                            onChangeHandler={(e) => driverFileUploadHandler(e)}
                            loadingFile={loadingFile}
                            subTitle="Import Bulk Driver Data"
                            subBody={'Only .xlsx file supported'}
                            dlTxt="sample-driver-list.xlsx"
                            file={drvFile}
                        />
                        {drvData && <DrvLoadData data={drvData} onAzSave={onDrvSave} />}
                    </TabPanel>
                    <TabPanel value="2">
                        <ImportPanel
                            onChangeHandler={(e) => truckFileUploadHandler(e)}
                            loadingFile={loadingFile}
                            subTitle="Import Bulk Truck Data"
                            subBody={'Only .xlsx file supported'}
                            dlTxt="sample-truck-list.xlsx"
                            file={trkFile}
                        />
                        {trkData && <TrkLoadData data={trkData} onSave={onTrkSave} />}
                    </TabPanel>
                    <TabPanel value="3">
                        <ImportPanel
                            onChangeHandler={(e) => trailerFileUploadHandler(e)}
                            loadingFile={loadingFile}
                            subTitle="Import Bulk Trailer Data"
                            subBody={'Only .xlsx file supported'}
                            dlTxt="sample-trailer-list.xlsx"
                            file={trlFile}
                        />
                        {trlData && <TrlLoadData data={trlData} onSave={onTrlSave} />}
                    </TabPanel>

                    <TabPanel value="4">
                        <ImportPanel
                            onChangeHandler={(e) => customerFileUploadHandler(e)}
                            loadingFile={loadingFile}
                            subTitle="Import Bulk Customer Data"
                            subBody={'Only .xlsx file supported'}
                            dlTxt="sample-customer-list.xlsx"
                            file={csFile}
                        />
                        {csData && <CsLoadData data={csData} onSave={onCsSave} />}
                    </TabPanel>

                    <TabPanel value="5">
                        <ImportPanel
                            onChangeHandler={(e) => loadFileUploadHandler(e)}
                            loadingFile={loadingFile}
                            subTitle="Import Bulk Load Data"
                            subBody={'Only .xlsx file supported'}
                            dlTxt="sample-load-list.xlsx"
                            file={ldFile}
                        />
                        {ldData && <LdLoadData data={ldData} onSave={onLdSave} />}
                    </TabPanel>

                    <TabPanel value="6">
                        <ImportPanelAz
                            onChangeHandler={(e) => azLoadFileUploadHandler(e)}
                            loadingFile={loadingFile}
                            subTitle="Import Bulk Load Data"
                            subBody={'Only .pdf file from Amazon supported'}
                            dlTxt="sample-load-list.csv"
                            file={azFile}
                            driver={driver}
                            changeDriver={(e, val) => setDriver(val)}
                            sx={{ background: 'red' }}
                            data={ldAzData}
                        />
                        {ldAzData && <AzLoadData data={ldAzData} onAzSave={onAzSave} />}
                    </TabPanel>
                </TabContext>
            </SubCard>

            <ImportDriverData open={openDrvModal} handleCloseModal={closeDrvHandler} data={drvData} />
            <ImportTruckData open={openTrkModal} handleCloseModal={closeTrkHandler} data={trkData} />
            <ImportTrailerData open={openTrlModal} handleCloseModal={closeTrlHandler} data={trlData} />
            <ImportCustomerData open={openCsModal} handleCloseModal={closeCsHandler} data={csData} />
            <ImportLoadData open={openLdModal} handleCloseModal={closeLdHandler} data={ldData} />
            <ImportAzLoadData open={openAzLdModal} handleCloseModal={closeLdAzHandler} data={ldAzData} />
        </>
    );
};

export default DataImportForm;
