import { useState, createRef } from 'react';
import MaterialTable from '@material-table/core';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { format, parseISO } from 'date-fns';

import TABLE_ICONS from 'tableIcons';

import { Button, IconButton, Stack, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import axios from 'axios';

import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';

import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';

import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import InvDocModal from 'ui-component/modals/InvDocModal';
import PdfDocPrevModal from 'ui-component/modals/PdfDocPrevModal';
import PdfModal from 'ui-component/modals/PdfModal';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { CheckBox } from '@mui/icons-material';

const AllOtherDocForm = (props) => {
    const { id } = useParams();
    const { inv } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tableRef = createRef();
    const [openModal, setOpenModal] = useState(false);
    const [openDocModal, setOpenDocModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idList, setIdList] = useState([]);
    const [docPreview, setDocPreview] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [checked, setChecked] = useState(false);

    const [loadData, setLoadData] = useState('');

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const cid = loginInfoFromStorage.company_id;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    const handleClickOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        tableRef.current.onQueryChange();
    };

    const handleClickOpenDocModal = () => {
        setOpenDocModal(true);
    };

    const handleCloseDocModal = () => {
        setOpenDocModal(false);
    };

    const handleClickOpenDoc = (file) => {
        setFileName(file);

        setModalOpen(true);
    };

    const handleCloseDoc = () => {
        setModalOpen(false);
    };

    function handleDownloadModalDoc(file) {
        window.open(file, '_blank');
    }

    const deleteDoc = async (id) => {
        try {
            await axios.delete(`/v1/api/dispatch/load-doc/view/${id}/`, {
                data: {},
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });
            tableRef.current.onQueryChange();
            dispatch(openSuccessSnackbar('Document  Deleted successfully'));
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const handleDelete = (rowsToDelete) => {
        setIdList(rowsToDelete.length > 0 ? rowsToDelete.map(({ dsp_load_doc_id }) => dsp_load_doc_id) : []);
    };

    const fileTypeHandler = (type) => {
        if (type === 'bol_doc') {
            return 'BOL';
        } else if (type === 'arc_doc') {
            return 'RC';
        } else if (type === 'ainv_doc') {
            return 'Invoice';
        } else if (type === 'invoice_doc') {
            return 'Consolidated Invoice';
        } else if (type === 'lumper_fee') {
            return 'Lumper Fee';
        } else if (type === 'detention_fee') {
            return 'Detention Fee';
        } else if (type === 'loading_fee') {
            return 'Loading/Unloading Fee';
        } else if (type === 'storage_fee') {
            return 'Storage Fee';
        } else if (type === 'accessorial_fee') {
            return 'Accessorial Fee';
        } else {
            return 'Other';
        }
    };
    const invoiceHandler = async () => {
        if (idList.length > 0) {
            setLoading(true);

            try {
                const { data } = await axios.post(`/v1/api/dispatch/load-invoice/generate/${id}/`, { id_list: idList, inv_id: inv }, config);

                setLoading(false);
                setDocPreview(data.map(({ oth_doc }) => oth_doc));
                dispatch(openSuccessSnackbar('Invoice updated successfully'));
                handleClickOpenDocModal();
            } catch (error) {
                if (process.env.NODE_ENV == 'development') {
                    console.log(error.response.data);
                }
                setLoading(false);
                dispatch(openErrorSnackbar('Something Went Wrong!'));
            }
        } else {
            dispatch(openErrorSnackbar('You Must select atleast 1 file to continue'));
        }
    };

    const approveHandler = async () => {
        setLoading(true);
        try {
            const { data } = await axios.post(`/v1/api/dispatch/load-invoice/confirm/${id}/`, { inv_id: inv }, config);

            setLoading(false);
            dispatch(openSuccessSnackbar('Invoice created successfully'));
            navigate('/dispatch-center');
        } catch (error) {
            setLoading(false);

            dispatch(openErrorSnackbar('Something Went Wrong!'));
        }
    };

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor"> Select</th>
                    <th className="headerTd rightHidBor"> Name</th>
                    <th className="headerTd rightHidBor">Type</th>
                    <th className="headerTd rightHidBor">File Type</th>
                    <th className="headerTd rightHidBor">Upload By</th>
                    <th className="headerTd rightHidBor">Upload Time</th>
                    <th className="headerTd">Actions</th>
                </tr>
            </thead>
        );
    };

    const handleChange = (event, data) => {
        setChecked(event.target.checked);
        const index = idList.findIndex((row) => row === data.data.dsp_load_doc_id);
        if (index === -1) {
            setIdList([...idList, data.data.dsp_load_doc_id]);
        } else {
            const updatedRows = [...idList];
            updatedRows.splice(index, 1);
            setIdList(updatedRows);
        }
    };

    const renderRow = (rowData) => {
        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Checkbox
                        checked={idList.some((row) => row === rowData.data.dsp_load_doc_id)}
                        onChange={(e) => handleChange(e, rowData)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.oth_doc_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {fileTypeHandler(rowData.data.doc_type)}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    <Typography>{rowData.data.oth_doc_name.split('.').pop()}</Typography>
                </td>

                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {format(parseISO(rowData.data.created_at), 'dd-MM-yyyy')}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {format(parseISO(rowData.data.created_at), 'dd-MM-yyyy')}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <IconButton disabled={!rowData.data.oth_doc} onClick={() => handleClickOpenDoc(rowData.data.oth_doc)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton disabled={!rowData.data.oth_doc} onClick={() => handleDownloadModalDoc(rowData.data.oth_doc)}>
                            <DownloadIcon />
                        </IconButton>

                        <IconButton disabled={rowData.data.doc_type !== 'other_doc'} onClick={() => deleteDoc(rowData.data.dsp_load_doc_id)}>
                            <DeleteSharpIcon />
                        </IconButton>
                    </Stack>
                </td>
            </tr>
        );
    };

    return (
        <>
            <Stack sx={{ mt: 5, mb: 2 }} direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                <Typography sx={{ fontSize: '24px' }}>Select file to be included with invoice</Typography>
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            mr: 2,
                            color: 'white',
                            backgroundColor: '#EB812E',
                            color: 'white',
                            fontFamily: 'Lexend',
                            fontWeight: 400,
                            lineHeight: '20px',

                            height: '36px',
                            fontSize: '16px',
                            borderRadius: '4px'
                        }}
                        color="orange"
                        onClick={handleClickOpenModal}
                    >
                        ADD NEW
                    </Button>
                </div>
            </Stack>

            <MaterialTable
                tableRef={tableRef}
                style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                data={(query) =>
                    new Promise((resolve, reject) => {
                        fetch('/v1/api/dispatch/load-doc/list/' + id + '/', {
                            method: 'get',

                            headers: new Headers({
                                'Content-type': 'application/json',
                                Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
                            })
                        })
                            .then((response) => response.json())
                            .then((result) => {
                                setLoadData(result);
                                resolve({
                                    data: result,
                                    page: query.page,
                                    totalCount: result.count
                                });
                            });
                    })
                }
                title=""
                columns={[
                    {
                        title: ' Name',
                        field: 'oth_doc_name'
                    },
                    {
                        title: 'Type',
                        filtering: false,
                        field: 'ext',
                        render: (rowData) => (
                            <>
                                <Typography>{rowData.oth_doc_name.split('.').pop()}</Typography>
                            </>
                        )
                    },
                    {
                        title: 'File Type',
                        render: (rowData) => fileTypeHandler(rowData.doc_type)
                    },
                    {
                        title: 'Upload By',
                        field: 'user'
                    },
                    {
                        title: 'Upload Time',
                        render: (rowData) => format(parseISO(rowData.created_at), 'dd-MM-yyyy')
                    },

                    {
                        title: 'Actions',
                        filtering: false,
                        align: 'center',
                        render: (rowData) => (
                            <>
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                    <IconButton disabled={!rowData.oth_doc} onClick={() => handleClickOpenDoc(rowData.oth_doc)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                    <IconButton disabled={!rowData.oth_doc} onClick={() => handleDownloadModalDoc(rowData.oth_doc)}>
                                        <DownloadIcon />
                                    </IconButton>
                                    <IconButton disabled={rowData.doc_type !== 'other_doc'} onClick={() => deleteDoc(rowData.dsp_load_doc_id)}>
                                        <DeleteSharpIcon />
                                    </IconButton>
                                </Stack>
                            </>
                        )
                    }
                ]}
                icons={TABLE_ICONS}
                components={{ Row: renderRow, Header: renderHeader }}
                localization={{
                    body: {
                        emptyDataSourceMessage: <CustomNoRowsOverlay />
                    }
                }}
                options={{
                    selection: true,
                    paging: false,
                    filtering: false,
                    search: false
                }}
                onSelectionChange={(rows) => {
                    handleDelete(rows);
                }}
            />

            {/* </SubCard> */}
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 5 }}>
                <LoadingFormButton type="button" onClickHandler={() => navigate(-1)} color="orange" variant="contained" btnText="Cancel" />

                <LoadingFormButton variant="contained" sx={{ mr: 2 }} color="orange" load={loading} onClickHandler={invoiceHandler} btnText="Create Invoice" />
            </Stack>

            <InvDocModal open={openModal} handleClose={handleCloseModal} id={id} />
            <PdfModal
                open={modalOpen}
                fullWidth
                fullScreen
                maxWidth="md"
                handleClose={() => handleCloseDoc()}
                handleDownload={() => handleDownloadModalDoc()}
                approveHandler={() => approveHandler()}
                type="otherDoc"
                fileName={fileName}
                content={
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <Viewer fileUrl={fileName} />
                    </Worker>
                }
            />
            <PdfDocPrevModal
                open={openDocModal}
                handleClose={handleCloseDocModal}
                docPreview={docPreview}
                approveHandler={approveHandler}
                loading={loading}
                type="other"
            />
        </>
    );
};

export default AllOtherDocForm;
