import { createRef, useEffect } from 'react';
import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import MaterialTable from '@material-table/core';

import TABLE_ICONS from 'tableIcons';

import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';

import axios from 'axios';
import CustomNoRowsOverlay from 'ui-component/CustomNoRowsOverlay';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import AddFormButton from 'ui-component/buttons/AddFormButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// third-party

import DriverFilter from 'ui-component/filters/DriverFilter';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Status from 'ui-component/buttons/Status';
import SearchIcon from '@mui/icons-material/Search';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import user_types from 'constant/user_types';
import { Box, width } from '@mui/system';
import { PatternFormat } from 'react-number-format';
import DrvRegform from './DrvRegForm';

const AllDriverForm = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // eslint-disable-next-line prefer-const

    const tableRef = createRef();

    const [open, setOpen] = useState(false);
    const [regOpen, setRegOpen] = useState(false);

    const [checked, setChecked] = useState(['doh']);
    const [statusChecked, setStatusChecked] = useState([]);
    const [startDot, setStartDot] = useState('');
    const [endDot, setEndDot] = useState('');
    const [startDoh, setStartDoh] = useState('');
    const [endDoh, setEndDoh] = useState('');
    const [searchSts, setSearchSts] = useState('');
    const [searchFltr, setSearchFltr] = useState('');
    const [selected, setSelected] = useState([]);
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const user_type = loginInfoFromStorage.user_type;
    const permissions = loginInfoFromStorage.permissions || {};
    const cid = loginInfoFromStorage.user_type === 'super_dispatcher_admin' ? '' : loginInfoFromStorage.company_id;

    const [currPage, setCurrPage] = useState(1);
    const [cardPerPage, setCardPerPage] = useState(5);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 1024px)');
    const isSmall = useMediaQuery('(min-width: 344px) and (max-width: 360px)');

    const deleteDriver = async (id, popupState) => {
        try {
            const { data } = await axios.delete(`/v1/api/users/company/driver/${id}/`, {
                data: {},
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${loginInfoFromStorage.token}`
                }
            });

            popupState.close();
            tableRef.current.onQueryChange();
            dispatch(openSuccessSnackbar('Driver  Deleted successfully'));
        } catch (error) {
            dispatch(openErrorSnackbar(error.response.data.detail));
        }
    };

    const handleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleStatus = (value) => () => {
        const currentIndex1 = statusChecked.indexOf(value);
        const newChecked1 = [...statusChecked];

        if (currentIndex1 === -1) {
            newChecked1.push(value);
        } else {
            newChecked1.splice(currentIndex1, 1);
        }

        setStatusChecked(newChecked1);

        if (newChecked1.includes('active') && newChecked1.includes('inactive')) {
            setSearchSts();
        } else if (newChecked1.includes('active')) {
            setSearchSts(true);
        } else if (newChecked1.includes('inactive')) {
            setSearchSts(false);
        } else {
            setSearchSts('');
        }
    };

    const handleClose = (event) => {
        setOpen(false);
    };

    const editDriver = (id, popupState) => {
        popupState.close();
        navigate(`/fleet/driver/edit/${id}`);
    };

    const viewDriver = (id, popupState) => {
        popupState.close();
        navigate(`/fleet/driver/view/${id}`);
    };

    const reset = () => {
        setStartDoh('');
        setEndDoh('');
        setStartDot('');
        setEndDot('');
        setSearchSts('');
        setChecked([]);
        setStatusChecked([]);
        if (tableRef.current) {
            console.log('tableRef.current:', tableRef.current);
            tableRef.current.onQueryChange();
        } else {
            console.error('tableRef.current is undefined');
        }
    };
    function downloadCSVHandler() {
        window.open(`/v1/api/users/company/drivers/download-csv/${cid}`, '_blank');
    }

    function downloadExcelHandler() {
        window.open(`/v1/api/users/company/drivers/download-excel/${cid}`, '_blank');
    }

    const bgClr = (rowData) => {
        return rowData.data.tableData.id % 2 ? 'rgba(221, 230, 255, 0.5)' : 'rgba(221, 230, 255, 0.3)';
    };
    const renderHeader = () => {
        return (
            <thead>
                <tr>
                    {/* Custom header cells */}
                    <th className="headerTd rightHidBor ">Driver Name</th>
                    <th className="headerTd rightHidBor">Phone#</th>
                    <th className="headerTd rightHidBor">Email</th>
                    <th className="headerTd rightHidBor">Truck</th>
                    <th className="headerTd rightHidBor">Trailer</th>
                    <th className="headerTd rightHidBor">Date of hiring</th>
                    <th className="headerTd rightHidBor">Date of Termination</th>
                    <th className="headerTd rightHidBor">Status</th>
                    <th className="headerTd">Actions</th>
                </tr>
            </thead>
        );
    };

    const renderRow = (rowData) => {
        // console.log('rowData: ', rowData);
        setSelected(rowData.data);

        return (
            <tr>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user.first_name} {rowData.data.user.last_name}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user.phone && rowData.data.user.phone !== '' && (
                        <PatternFormat value={rowData.data.user.phone} displayType={'text'} format="###-###-####" />
                    )}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.user.email}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.truck}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.trailer}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.hire_date}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.end_date ? rowData.data.end_date : 'N/A'}
                </td>
                <td className="rowTr rightHidBor" style={{ backgroundColor: bgClr(rowData) }}>
                    {rowData.data.is_active ? <Status text="Active" /> : <Status text="Inactive" />}
                </td>
                {/* Custom cell or additional cells */}
                <td className="rowTr" style={{ backgroundColor: bgClr(rowData) }}>
                    <PopupState variant="popover" popupId={`demoMenu-${rowData.data.driver_id}`}>
                        {(popupState) => (
                            <div>
                                <IconButton {...bindTrigger(popupState)} size="large">
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    {((permissions.driver && permissions.driver.change) || user_types.includes(user_type)) && (
                                        <MenuItem onClick={() => editDriver(rowData.data.driver_id, popupState)}>Edit Driver</MenuItem>
                                    )}
                                    <MenuItem onClick={() => viewDriver(rowData.data.driver_id, popupState)}>View Driver</MenuItem>
                                    {(cid === '' || (permissions.driver && permissions.driver.delete)) && (
                                        <MenuItem onClick={() => deleteDriver(rowData.data.driver_id, popupState)}>Remove Driver</MenuItem>
                                    )}
                                </Menu>
                            </div>
                        )}
                    </PopupState>
                </td>
            </tr>
        );
    };

    const fetchData = async (query) => {
        setLoading(true);

        console.log('Applying filters:', { startDoh, endDoh, startDot, endDot, statusChecked });

        // Define filter parameters
        const filters = [];
        if (startDoh) filters.push(`start_hire_date=${encodeURIComponent(startDoh)}`);
        if (endDoh) filters.push(`end_hire_date=${encodeURIComponent(endDoh)}`);
        if (startDot) filters.push(`start_end_date=${encodeURIComponent(startDot)}`);
        if (endDot) filters.push(`end_end_date=${encodeURIComponent(endDot)}`);
        if (statusChecked) filters.push(`status=${encodeURIComponent(statusChecked)}`);

        // console.log(filters);

        try {
            const url = `/v1/api/users/company/drivers?page=${query.page + 1}&page_size=${
                query.pageSize
            }&search=${searchFltr}&start_hire_date=${startDoh}&end_hire_date=${endDoh}&start_end_date=${startDot}&end_end_date=${endDot}&company_id=${cid}&is_active=${searchSts}`;

            const response = await fetch(url);
            const result = await response.json();

            // Set data
            setSelected(result.results || []);
            setTotalCount(result.count || 0);

            return {
                data: result.results,
                page: query.page,
                totalCount: result.count
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            return {
                data: [],
                page: query.page,
                totalCount: 0
            };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMobile || isTablet) {
            fetchData({ page: 0, pageSize: 5 })
                .then((result) => {
                    setSelected(result.data || {});
                    setTotalCount(result.totalCount || 0);
                })
                .catch((error) => console.error('Error fetching data: ', error));
        }
        // console.log(selected);
    }, [isMobile, currPage, cardPerPage, isTablet]);

    const handleChangePage = (event, newPage) => {
        setCurrPage(newPage + 1);
        setLoading(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCardPerPage(parseInt(event.target.value, 10));
        setCurrPage(1);
    };

    // ========================= Driver Registration Email =====================================
    const handleRegOpen = () => {
        setRegOpen(true);
    };
    const handleRegClose = () => {
        setRegOpen(false);
    };
    // ========================= Driver Registration Email =====================================

    return (
        <>
            <Stack
                sx={{ mt: 5, border: 'none', mb: 2, background: 'transparent' }}
                direction={matchDownMd ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={matchDownMd ? 'flex-start' : 'center'}
                spacing={5}
            >
                <TextField
                    type="text"
                    size="small"
                    placeholder="Search"
                    value={searchFltr}
                    onChange={(e) => {
                        setSearchFltr(e.target.value);
                        tableRef.current.onQueryChange();
                    }}
                    // sx={{ width: { xs: '310px', sm: '350px' } }}
                    sx={{ width: { xs: '82vw', sm: '45vw', lg: '20vw' } }}
                    className="search-input"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />

                <Box sx={{ whiteSpace: 'nowrap' }}>
                    <DriverFilter
                        checked={checked}
                        handleClose={handleClose}
                        handleChecked={handleChecked}
                        startDoh={startDoh}
                        startdohHandler={(e) => setStartDoh(format(new Date(e), 'yyyy-MM-dd'))}
                        endDoh={endDoh}
                        enddohHandler={(e) => setEndDoh(format(new Date(e), 'yyyy-MM-dd'))}
                        startDot={startDot}
                        startdotHandler={(e) => setStartDot(format(new Date(e), 'yyyy-MM-dd'))}
                        endDot={endDot}
                        enddotHandler={(e) => setEndDot(format(new Date(e), 'yyyy-MM-dd'))}
                        statusChecked={statusChecked}
                        handleStatus={handleStatus}
                        filterHandler={() => {
                            setOpen((prevOpen) => !prevOpen);
                            console.log(tableRef);
                            if (tableRef.current) {
                                console.log('Applying filters:', { startDoh, endDoh, startDot, endDot, statusChecked });
                                tableRef.current.onQueryChange();
                            } else {
                                console.error('tableRef.current is undefined on mobile');
                                const query = {
                                    page: 0,
                                    pageSize: 5
                                };

                                fetchData(query);
                            }
                        }}
                        reset={reset}
                    />

                    <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleRegOpen}>
                        Send Registration Email
                    </Button>

                    {((permissions.driver && permissions.driver.add) || user_types.includes(user_type)) && (
                        <AddFormButton url="/fleet/driver/add" btnText="ADD NEW DRIVER" />
                    )}

                    <PopupState variant="popover" popupId="1">
                        {(popupState) => (
                            <>
                                <IconButton
                                    size={matchDownMd ? 'small' : 'medium'}
                                    variant="contained"
                                    sx={{ mr: 2 }}
                                    color="orange"
                                    {...bindTrigger(popupState)}
                                >
                                    <FileDownloadIcon />
                                </IconButton>
                                <Menu
                                    {...bindMenu(popupState)}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <MenuItem onClick={() => downloadCSVHandler()}>Export CSV</MenuItem>
                                    <MenuItem onClick={() => downloadExcelHandler()}>Export Excel</MenuItem>
                                </Menu>
                            </>
                        )}
                    </PopupState>
                </Box>
            </Stack>

            {isMobile ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                    // backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : 'rgba(221, 230, 255, 0.3)'
                                }}
                            >
                                <CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                        <Box sx={{ float: 'left' }}>
                                            {driver.is_active ? <Status text="Active" /> : <Status text="Inactive" />}

                                            {/* <Status text="Active" /> */}
                                        </Box>

                                        <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                            {(popupState) => (
                                                <div style={{ float: 'right', marginTop: '1px' }}>
                                                    <IconButton {...bindTrigger(popupState)} size="large">
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                    <Menu
                                                        {...bindMenu(popupState)}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    >
                                                        {((permissions.driver && permissions.driver.change) || user_types.includes(user_type)) && (
                                                            <MenuItem onClick={() => editDriver(driver.driver_id, popupState)}>Edit Driver</MenuItem>
                                                        )}
                                                        <MenuItem onClick={() => viewDriver(driver.driver_id, popupState)}>View Driver</MenuItem>
                                                        {(cid === '' || (permissions.driver && permissions.driver.delete)) && (
                                                            <MenuItem onClick={() => deleteDriver(driver.driver_id, popupState)}>Remove Driver</MenuItem>
                                                        )}
                                                    </Menu>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>

                                    <Box sx={{ clear: 'both' }} />
                                    <Typography sx={{ marginBottom: '.5rem' }}>
                                        Driver Name : {driver.user.first_name} {driver.user.last_name}
                                    </Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Phone# : {driver.user.phone}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Email : {driver.user.email}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Truck : {driver.truck}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Trailer : {driver.trailer}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Date of hiring : {driver.hire_date}</Typography>
                                    <Typography sx={{ marginBottom: '.5rem' }}>Date of Termination : {driver.end_date ? driver.end_date : 'N/A'}</Typography>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                display: 'grid',
                                gridTemplateColumns: '4rem 11rem',
                                gridTemplateRows: 'auto auto',
                                alignItems: 'center',
                                gap: '8px' // Adjust as needed
                            },
                            '.MuiTablePagination-selectLabel': {
                                gridColumn: '2/3',
                                gridRow: '1 / 2',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end'
                            },
                            '.MuiTablePagination-input': {
                                gridColumn: '2 / 4',
                                gridRow: '1 / 2',
                                marginRight: 0
                            },
                            '.MuiTablePagination-displayedRows': {
                                gridColumn: '1 / 2',
                                gridRow: '2 / 3',
                                textWrap: 'nowrap',
                                textAlign: isSmall ? 'center' : 'end',
                                marginLeft: isSmall ? '8vw' : '20vw'
                            },
                            '.MuiTablePagination-actions': {
                                gridColumn: '1/4',
                                gridRow: '2 / 3',
                                display: 'flex',
                                alignItems: 'center',
                                // gap: '8px',
                                justifyContent: 'flex-end'
                            }
                        }}
                    />
                </>
            ) : isTablet ? (
                <>
                    {loading ? (
                        <>
                            <Box width={'85vw'} height={'50vh'} display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        selected &&
                        selected?.map((driver, idx) => (
                            <Card
                                key={driver.driver_id}
                                sx={{
                                    marginTop: '2rem',
                                    marginBottom: '1.5rem',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                    borderRadius: '15px',
                                    backgroundColor: idx % 2 !== 0 ? '#DDE6FF' : '#d1deff70'
                                }}
                            >
                                <CardContent>
                                    <PopupState variant="popover" popupId={`demoMenu-${driver.driver_id}`}>
                                        {(popupState) => (
                                            <div style={{ float: 'right', marginTop: '1px' }}>
                                                <IconButton {...bindTrigger(popupState)} size="large">
                                                    <MoreHorizIcon />
                                                </IconButton>
                                                <Menu
                                                    {...bindMenu(popupState)}
                                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                >
                                                    {((permissions.driver && permissions.driver.change) || user_types.includes(user_type)) && (
                                                        <MenuItem onClick={() => editDriver(driver.driver_id, popupState)}>Edit Driver</MenuItem>
                                                    )}
                                                    <MenuItem onClick={() => viewDriver(driver.driver_id, popupState)}>View Driver</MenuItem>
                                                    {(cid === '' || (permissions.driver && permissions.driver.delete)) && (
                                                        <MenuItem onClick={() => deleteDriver(driver.driver_id, popupState)}>Remove Driver</MenuItem>
                                                    )}
                                                </Menu>
                                            </div>
                                        )}
                                    </PopupState>
                                    {/* </Box> */}
                                    <Box sx={{ clear: 'both' }} />

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            {' '}
                                            Driver Name : {driver.user.first_name} {driver.user.last_name}
                                        </Typography>

                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Phone# : {driver.user.phone}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '0.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Email : {driver.user.email}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Truck : {driver.truck}</Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '.6rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Date of hiring : {driver.hire_date}</Typography>
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>
                                            Date of Termination : {driver.end_date ? driver.end_date : 'N/A'}
                                        </Typography>{' '}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                            gap: '1.5rem',
                                            marginBottom: '0.5rem'
                                        }}
                                    >
                                        <Typography sx={{ marginBottom: '.5rem', fontSize: '2vw' }}>Trailer : {driver.trailer}</Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))
                    )}

                    <TablePagination
                        showFirstButton
                        showLastButton
                        component="div"
                        count={totalCount}
                        page={currPage - 1}
                        // onPageChange={handleChangePage}
                        onPageChange={(event, newPage) => {
                            handleChangePage(event, newPage);
                            fetchData({ page: newPage, pageSize: cardPerPage });
                        }}
                        rowsPerPage={cardPerPage}
                        rowsPerPageOptions={[20, 50, 100]}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            ) : (
                <MaterialTable
                    tableRef={tableRef}
                    style={{ backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: '5px' }}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            fetchData(query)
                                .then((result) => resolve(result))
                                .catch((error) => reject(error));
                        })
                    }
                    title="All Drivers"
                    columns={[]}
                    icons={TABLE_ICONS}
                    components={{ Row: renderRow, Header: renderHeader }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: <CustomNoRowsOverlay />
                        }
                    }}
                    options={{
                        sorting: true,
                        exportButton: false,
                        toolbar: false,
                        draggable: false,
                        columnResizable: true
                    }}
                />
            )}

            <DrvRegform open={regOpen} handleClose={handleRegClose} />
        </>
    );
};
export default AllDriverForm;
