import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import AnimateButton from 'ui-component/extended/AnimateButton';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import { PatternFormat } from 'react-number-format';
import { useState } from 'react';
import { useEffect } from 'react';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import axios from 'axios';
import * as Yup from 'yup';
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function DrvRegform({ fullWidth, fullScreen, maxWidth, open, handleClose, type, fileId = '', safteyDocList, reloadHandler, id = null }) {
    const [companySetup, setCompanySetup] = useState();
    const dispatch = useDispatch();
    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };
    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }
    }, [companySetup]);
    const fetchCompany = async () => {
        // setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);
            setCompanySetup(data);
            // setLoading(false);
        } catch (error) {
            // setLoading(false);
        }
    };
    const MemberSchema = Yup.object().shape({
        driverName: Yup.string().required('Driver Name is a required field'),
        driverEmail: Yup.string().email().required('Driver Email is a required field'),
        contactName: Yup.string().required('Contact Person Name is a required field'),
        contactEmail: Yup.string().email().required('Contact Person Email is a required field'),
        contactPhone: Yup.string().required('Contact Person Phone is a required field')
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            driverName: '',
            driverEmail: '',
            contactName: '',
            contactEmail: companySetup ? companySetup.email : '',
            contactPhone: companySetup ? companySetup.phone : ''
        },
        validationSchema: MemberSchema,
        onSubmit: async () => {
            try {
                const { data } = await axios.post(
                    `/v1/api/users/driver-registration-link/`,
                    {
                        driver_name: values.driverName,
                        driver_email: values.driverEmail,
                        contact_name: values.contactName,
                        contact_email: values.contactEmail,
                        contact_phone: values.contactPhone,
                        company_id: loginInfoFromStorage.company_id
                    },
                    config
                );
                // if (id) {
                //     const { data } = await axios.put(
                //         `/v1/api/users/company/cmp-doc-type/view/${id}`,
                //         {
                //             stf_doc_type_name: values.FileName,
                //             stf_doc_type: 'vq',
                //             doc_type: 'vq',
                //             created_by: loginInfoFromStorage.user_id,
                //             company_id: loginInfoFromStorage.company_id
                //         },
                //         config
                //     );
                // } else {
                //     const { data } = await axios.post(
                //         `/v1/api/users/company/cmp-doc-type/add/`,
                //         {
                //             stf_doc_type_name: values.FileName,
                //             stf_doc_type: 'vq',
                //             doc_type: 'vq',
                //             created_by: loginInfoFromStorage.user_id,
                //             company_id: loginInfoFromStorage.company_id
                //         },
                //         config
                //     );
                // }
                dispatch(openSuccessSnackbar('Registration Email Sent Successfully'));
                handleClose();
            } catch (response) {}
        }
    });
    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;
    const textFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Contact Person Phone',
        error: Boolean(touched.contactPhone && errors.contactPhone),
        helperText: touched.contactPhone && errors.contactPhone
    };
    return (
        <Dialog fullWidth fullScreen={false} maxWidth="sm" open={open} onClose={handleClose} TransitionComponent={Transition} scroll="paper">
            <DialogTitle>
                <Typography variant="h4" color="primary">
                    Send Driver Registration Link
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                        <Grid container spacing={3}>
                            {/* DQ type */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Driver Name"
                                    {...getFieldProps('driverName')}
                                    error={Boolean(touched.driverName && errors.driverName)}
                                    helperText={touched.driverName && errors.driverName}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Driver Email"
                                    {...getFieldProps('driverEmail')}
                                    error={Boolean(touched.driverEmail && errors.driverEmail)}
                                    helperText={touched.driverEmail && errors.driverEmail}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Contact Person Name"
                                    {...getFieldProps('contactName')}
                                    error={Boolean(touched.contactName && errors.contactName)}
                                    helperText={touched.contactName && errors.contactName}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    className="text-input"
                                    color="orange"
                                    type="text"
                                    label="Contact Person Email"
                                    {...getFieldProps('contactEmail')}
                                    error={Boolean(touched.contactEmail && errors.contactEmail)}
                                    helperText={touched.contactEmail && errors.contactEmail}
                                />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <PatternFormat
                                    format="###-###-####"
                                    allowEmptyFormatting
                                    mask="_"
                                    value={values.contactPhone}
                                    onValueChange={(value) => setFieldValue('contactPhone', value.value)}
                                    customInput={TextField}
                                    {...textFieldProps}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 2 }} align="center">
                            <AnimateButton>
                                <LoadingFormButton type="submit" btnText="Send Email" load={isSubmitting} />
                            </AnimateButton>
                        </Box>
                        {/* </Grid> */}
                    </Form>
                </FormikProvider>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default DrvRegform;
