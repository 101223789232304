import { useDispatch } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
// material-ui

import { Box, Button, FormControl, Grid, Skeleton, TextField, Typography } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';

import SubCard from 'ui-component/cards/SubCard';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BackFormButton from 'ui-component/buttons/BackFormButton';
import { Stack } from '@mui/system';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router';
import { openErrorSnackbar, openSuccessSnackbar } from 'store/ui/snackbarSlice';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { setLogo } from 'store/ui/customization';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const AccountingForm = () => {
    const [companySetup, setCompanySetup] = useState();
    const [loading, setLoading] = useState(false);
    const [fileObject, setFileObject] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AccountingSchema = Yup.object().shape({ clientNumber: Yup.string().required('Client Number is a required field') });

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'multipart/form-data',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    useEffect(() => {
        if (!companySetup) {
            fetchCompany();
        }
    }, [companySetup]);

    const fetchCompany = async () => {
        setLoading(true);
        // const id = 233;
        try {
            const { data } = await axios.get(`/v1/api/users/company/setup/`, config);
            setCompanySetup(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleFetchFile = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const fileName = url.split('/').pop();

            // Create a File object from the blob

            const fileObject = new File([blob], fileName, { type: blob.type });

            setFileObject(fileObject);
        } catch (error) {
            console.error('Error fetching or creating File object:', error);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            // faqQuestion: id && faqDetails ? faqDetails.faq_question : '',

            billingAddress: companySetup ? companySetup.billing_address : '',
            bPhone: companySetup ? companySetup.billing_phone : '',
            bFaxNo: companySetup ? companySetup.billing_fax : '',
            fCompanyName: companySetup ? companySetup.fact_info.fact_comp_name : '',
            fCompanyAddress: companySetup ? companySetup.fact_info.fact_address : '',
            fPhone: companySetup ? companySetup.fact_info.fact_phone : '',
            fFaxNo: companySetup ? companySetup.fact_info.fact_fax : '',
            clientNumber: companySetup ? companySetup.fact_info.fact_client_no : '',
            file: companySetup ? companySetup.logo : '',
            fileTmp: companySetup ? companySetup.logo : '',
            fact_email_address: companySetup ? companySetup.fact_info.fact_email_address : '',

            files: companySetup && companySetup?.docs?.length !== 0 ? companySetup.docs : []
        },
        validationSchema: AccountingSchema,
        onSubmit: async () => {
            if (typeof values.file === 'string') {
                handleFetchFile(values.file);
            }

            console.log(values.file);
            try {
                const setup = new FormData();
                setup.append('dsp_company_name', companySetup && companySetup.dsp_company_name);
                setup.append('email', companySetup && companySetup.email);
                setup.append('billing_address', values.billingAddress);
                setup.append('billing_phone', values.bPhone);
                setup.append('billing_fax', values.bFaxNo);
                setup.append('fact_client_no', values.clientNumber);
                setup.append('fact_email_address', values.fact_email_address);
                setup.append('fact_comp_name', values.fCompanyName);
                setup.append('fact_address', values.fCompanyAddress);
                setup.append('fact_phone', values.fPhone);
                setup.append('fact_fax', values.fFaxNo);
                // setup.append('logo', fileObject ? fileObject : values.file);
                // setup.append('logo', values.file);

                if (typeof values.file !== 'string') {
                    setup.append('logo', values.file);
                }

                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].sft_doc`, i.sft_doc));
                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].sft_doc_name`, i.sft_doc_name));
                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].cmp_doc_id`, i.cmp_doc_id));
                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].doc_type_name`, i.doc_type_name));
                values.files.forEach((i, index) => setup.append(`saftey_docs[${index}].doc_name`, i.doc_name));

                const { data } = await axios.post(`/v1/api/users/company/setup/`, setup, config);
                dispatch(setLogo(data.logo));

                dispatch(openSuccessSnackbar('Accounting Details updated successfully'));
                navigate('/company/profile', { replace: true });
            } catch (response) {
                dispatch(openErrorSnackbar('Something Went Wrong'));
            }
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    const billingTextFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.'
    };
    const billingFaxFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Fax.'
    };

    const factoringTextFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Phone No.'
    };

    const factoringFaxFieldProps = {
        fullWidth: true,
        className: 'text-input',
        color: 'orange',
        label: 'Fax.'
    };
    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3} sx={{ mt: 5 }}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography variant="h2">Invoice Settings</Typography>
                            <Typography variant="subtitle">Setup the details for your invoices generation</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                                <FormControl variant="outlined" align="right">
                                    {/* <LoadingFormButton type="button" btnText="Edit" onClick={() => editOrganization} /> */}
                                    <BackFormButton />
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>

                    <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                        <Grid container spacing={3}>
                            {/* Customer */}
                            {/* General Info */}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h2">Company Details</Typography>
                            </Grid>
                            {/* Company Address*/}
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <TextField
                                                className="text-input"
                                                fullWidth
                                                type="text"
                                                label="Billiing Address"
                                                {...getFieldProps('billingAddress')}
                                                // error={Boolean(
                                                //     (touched.companyName && errors.companyName) ||
                                                //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                                //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                                // )}
                                                // helperText={
                                                //     (touched.companyName && errors.companyName) ||
                                                //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                                //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                                // }
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <PatternFormat
                                                format="###-###-####"
                                                allowEmptyFormatting
                                                mask="_"
                                                value={values.bPhone}
                                                onValueChange={(value) => setFieldValue('bPhone', value.value)}
                                                customInput={TextField}
                                                {...billingTextFieldProps}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <PatternFormat
                                                format="###-###-####"
                                                allowEmptyFormatting
                                                mask="_"
                                                value={values.bFaxNo}
                                                onValueChange={(value) => setFieldValue('bFaxNo', value.value)}
                                                customInput={TextField}
                                                {...billingFaxFieldProps}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                                <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                    {loading ? <Skeleton height="50px" /> : <img src={values.fileTmp} alt="test" height="150px" width="200px" />}

                                    <Button
                                        component="label"
                                        sx={{
                                            color: 'white',
                                            backgroundColor: '#EB812E',
                                            fontFamily: 'Lexend',
                                            fontWeight: 400,
                                            height: '36px',
                                            fontSize: '16px',
                                            borderRadius: '4px'
                                        }}
                                        color="orange"
                                        variant="contained"
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload
                                        <input
                                            hidden
                                            type="file"
                                            onChange={(event) => {
                                                let file = event.currentTarget.files[0];
                                                let url = URL.createObjectURL(file);
                                                setFieldValue('fileTmp', url);
                                                setFieldValue('file', file);
                                            }}
                                        />
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </SubCard>

                    <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                <Grid container spacing={3}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Typography variant="h2">Factoring Company Details</Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <TextField
                                                className="text-input"
                                                fullWidth
                                                type="text"
                                                label="Factoring Company Name"
                                                {...getFieldProps('fCompanyName')}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <TextField
                                                className="text-input"
                                                fullWidth
                                                type="text"
                                                label="Factoring Address"
                                                {...getFieldProps('fCompanyAddress')}
                                                // error={Boolean(
                                                //     (touched.companyName && errors.companyName) ||
                                                //         (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                                //         (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                                // )}
                                                // helperText={
                                                //     (touched.companyName && errors.companyName) ||
                                                //     (errorCompaniesAdd && errorCompaniesAdd.company_name) ||
                                                //     (errorCompaniesUpdate && errorCompaniesUpdate.company_name)
                                                // }
                                            />
                                        )}
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <PatternFormat
                                                format="###-###-####"
                                                allowEmptyFormatting
                                                mask="_"
                                                value={values.fPhone}
                                                onValueChange={(value) => setFieldValue('fPhone', value.value)}
                                                customInput={TextField}
                                                {...factoringTextFieldProps}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        {loading ? (
                                            <Skeleton height="50px" />
                                        ) : (
                                            <PatternFormat
                                                format="###-###-####"
                                                allowEmptyFormatting
                                                mask="_"
                                                value={values.fFaxNo}
                                                onValueChange={(value) => setFieldValue('fFaxNo', value.value)}
                                                customInput={TextField}
                                                {...factoringFaxFieldProps}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                <Typography variant="h2">Factoring Client Number</Typography>
                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            type="text"
                                            label="Client Number"
                                            {...getFieldProps('clientNumber')}
                                            error={Boolean(touched.clientNumber && errors.clientNumber)}
                                            helperText={touched.clientNumber && errors.clientNumber}
                                        />
                                    )}
                                </Grid>
                            </SubCard>
                            <SubCard sx={{ mt: 5, borderRadius: '8px', mb: 2 }}>
                                <Typography variant="h2">Factoring Email Address</Typography>
                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                                    {loading ? (
                                        <Skeleton height="50px" />
                                    ) : (
                                        <TextField
                                            fullWidth
                                            className="text-input"
                                            type="email"
                                            label="Factoring Email"
                                            {...getFieldProps('fact_email_address')}
                                            error={Boolean(touched.fact_email_address && errors.fact_email_address)}
                                            helperText={touched.fact_email_address && errors.fact_email_address}
                                        />
                                    )}
                                </Grid>
                            </SubCard>
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton load={isSubmitting} type="submit" btnText="Save" />
                        </AnimateButton>
                    </Box>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
        </>
    );
};

AccountingForm.propTypes = {
    companySetup: PropTypes.any
};

export default AccountingForm;
