import { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import { Box, Grid, TextField } from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';

// third party
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import LoadingFormButton from 'ui-component/buttons/LoadingFormButton';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { openSuccessSnackbar } from 'store/ui/snackbarSlice';
import NewFileTypeModal from 'ui-component/modals/NewFileTypeModal';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
// ===========================|| FIREBASE - REGISTER ||=========================== //

const AddCQFileTypeForm = ({ handleClose, safteyDocList, id }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [fileDetails, setFileDetails] = useState(null);

    const loginInfoFromStorage = localStorage.getItem('loginUserInfo') ? JSON.parse(localStorage.getItem('loginUserInfo')) : null;
    const config = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${loginInfoFromStorage && loginInfoFromStorage.token}`
        }
    };

    useEffect(() => {
        if (id) {
            fetchFileDetails();
        }
    }, [id]);

    const fetchFileDetails = async () => {
        try {
            const { data } = await axios.get(`/v1/api/users/company/cmp-doc-type/view/${id}`, config);
            setFileDetails(data);
        } catch (error) {
            // setUpdateError(error.response.data);
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const MemberSchema = Yup.object().shape({
        FileName: Yup.string().required('File Type Name is a required field')
    });

    const formik = useFormik({
        enableReinitialize: true,

        initialValues: {
            FileName: id && fileDetails ? fileDetails.stf_doc_type_name : ''
        },
        validationSchema: MemberSchema,
        onSubmit: async () => {
            try {
                if (id) {
                    const { data } = await axios.put(
                        `/v1/api/users/company/cmp-doc-type/view/${id}`,
                        {
                            stf_doc_type_name: values.FileName,
                            stf_doc_type: 'vq',
                            doc_type: 'vq',
                            created_by: loginInfoFromStorage.user_id,
                            company_id: loginInfoFromStorage.company_id
                        },
                        config
                    );
                } else {
                    const { data } = await axios.post(
                        `/v1/api/users/company/cmp-doc-type/add/`,
                        {
                            stf_doc_type_name: values.FileName,
                            stf_doc_type: 'vq',
                            doc_type: 'vq',
                            created_by: loginInfoFromStorage.user_id,
                            company_id: loginInfoFromStorage.company_id
                        },
                        config
                    );
                }

                dispatch(openSuccessSnackbar('VQ File Type  added successfully'));
                handleClose();
            } catch (response) {}
        }
    });

    const { errors, touched, values, setFieldValue, isSubmitting, getFieldProps, handleSubmit } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={3}>
                        {/* DQ type */}

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                fullWidth
                                className="text-input"
                                color="orange"
                                type="text"
                                label="File Type Name"
                                {...getFieldProps('FileName')}
                                error={Boolean(touched.FileName && errors.FileName)}
                                helperText={touched.FileName && errors.FileName}
                            />
                        </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }} align="center">
                        <AnimateButton>
                            <LoadingFormButton type="submit" btnText="Save" load={isSubmitting} />
                        </AnimateButton>
                    </Box>

                    {/* </Grid> */}
                </Form>
            </FormikProvider>
            <NewFileTypeModal open={open} handleClose={handleCloseModal} />
        </>
    );
};

AddCQFileTypeForm.propTypes = {
    handleClose: PropTypes.any,
    safteyDocList: PropTypes.any
};

export default AddCQFileTypeForm;
