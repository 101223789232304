import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography } from '@mui/material';
import { forwardRef } from 'react';

import AddDriverSafetyComplianceForm from 'ui-component/safetyCompliance/AddDriverSafetyComplianceForm';
import ListDriverSafetyComplianceForm from 'ui-component/safetyCompliance/ListDriverSafetyComplianceForm';
import ListTruckSafetyComplianceForm from 'ui-component/safetyCompliance/ListTruckSafetyComplianceForm';
import ListCompSafetyComplianceForm from './ListCompSafetyComplianceForm';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Modal component for document view
 * @param {boolean} fullWidth - true | false
 * @param {boolean} fullScreen - true | false
 * @param {boolean} open - state for the modal - true | false
 * @param {string} maxWidth - choices xs, sm, md, lg, xl
 * @param {function} handleClose
 * @param {function} handleDownload
 * @param {JSX} content
 *
 *
 * @returns JSX
 */
function CQListModal({ fullWidth, fullScreen, maxWidth, open, handleClose, id, docId, cid, tId, reloadData, docName }) {
    return (
        <Dialog fullWidth fullScreen={fullScreen} maxWidth="xl" open={open} onClose={handleClose} TransitionComponent={Transition} scroll="paper">
            <DialogTitle>
                <Typography variant="h4" color="primary">
                    {docName}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <ListCompSafetyComplianceForm handleClose={handleClose} docName={docName} id={id} docId={docId} cid={cid} tId={tId} reloadData={reloadData} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" sx={{ mr: 2, color: 'white', backgroundColor: '#EB812E' }} color="orange">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CQListModal.defaultProps = {
    fullScreen: false,
    fullWidth: true,
    maxWidth: 'xl'
};

export default CQListModal;
